import React from 'react'
import ProjectTaskTable from '../../../ProjectTask/ProjectTaskTable.js'

const ListTab = () => {
  return (
    <section>
      <ProjectTaskTable/>
    </section>
  )
}

export default ListTab