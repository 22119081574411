import React from "react";
import "./App.css";
import "./index.css";
import ProjectManagementRoutes from "./components/Project-Management/ProjectManagementRoutes";



function App() {
  return (
    <>
      <ProjectManagementRoutes />
    </>
  );
}

export default App;
